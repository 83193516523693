.signwall {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background-color: #f1f1f1;
  &-container {
    width: 100%;
    display: flex;
    box-shadow: 2px -1px 29px -10px #050504;
    border-radius: 0.25rem;
    background-color: #ffffff;
    flex-direction: column;
    margin: auto;

    & .banner {
      min-height: 100px;
      background-color: $fiveColor;
      width: auto;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      &-image {
        padding: 20px;
        opacity: 1;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        width: 100%;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      padding: 20px 30px 40px;
      &__title-container {
        font-family: Verdana, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        color: #000000;
        background: linear-gradient(
          0deg,
          transparent 49%,
          rgb(3, 0, 60) 50%,
          transparent 59%
        );
      }
      &__title {
        background-color: #ffffff;
        display: inline-block;
        max-width: 140px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        color: $fiveColor;
        text-align: center;
        margin: 10px auto;
        font-family: sans-serif;
      }
    }

    @media #{$--xs} {
      width: auto;
    }
    @media #{$--sm} {
      max-width: 400px;
    }
  }
}
