.header {
  display: flex;
  margin: 0 auto;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  &__container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    &__phone-container {
      display: flex;
      margin: 20px;
      width: auto;
      height: auto;
      &--mini {
        display: flex;
        &-text {
          margin-left: 10px;
          text-transform: uppercase;
          font-family: sans-serif;
          padding: 2px 2px 2px 0;
          font-size: 15px;
          @media #{$--sm} {
            font-size: 30px;
          }
        }
      }
    }
    &__schedules-container {
      display: none;
      margin: 10px auto;
      width: auto;
      flex-direction: column;
      height: auto;
      justify-items: center;
      justify-content: center;
      @media #{$--md} {
        display: flex;
      }
      &-text {
        text-shadow: 2px 2px 4px rgb(190, 190, 190);
        text-align: center;
        font-family: sans-serif;
        padding: 2px;
        font-size: 15px;
        @media #{$--sm} {
          font-size: 20px;
        }
      }
    }

    &__schedules-container-mobile {
      display: flex;
      margin: auto auto 10px;
      width: auto;
      flex-direction: column;
      height: auto;
      justify-items: center;
      justify-content: center;
      @media #{$--sm} {
        flex-direction: row;
      }
      @media #{$--md} {
        display: none;
      }
      &-text {
        text-shadow: 2px 2px 4px rgb(190, 190, 190);
        color: #17004e;
        text-align: center;
        font-family: sans-serif;
        font-size: 14px;
        font-weight: 700;
        @media #{$--sm} {
          margin: auto 10px;
          font-size: 20px;
        }
      }
    }

    &__exams-container {
      display: flex;
      margin: auto 20px auto auto;
      flex-direction: column;
      text-decoration: none;
      &--mini {
        -webkit-transform: skewx(-20deg);
        transform: skewx(-20deg);
        border-radius: 10px;
        text-decoration: none;
        background-color: #7b68d1;
        &:hover {
          transition: 0.5s background-color;
          background-color: #2a166f;
        }
        &-icon {
          -webkit-transform: skewx(20deg);
          transform: skewx(20deg);
          padding: 2px;
        }
        &-text {
          margin-left: 1px;
          -webkit-transform: skewx(20deg);
          transform: skewx(20deg);
          text-transform: uppercase;
          font-size: 10px;
          color: #ffffff;
          font-family: sans-serif;
          padding: 2px 2px 2px 0;
        }
      }
    }
  }
}

.nav {
  width: 100%;
  background-color: $fiveColor;
  color: white;
  align-items: center;
  font-family: sans-serif;
  position: sticky;
  top: 0;
  z-index: 3;
  @media #{$--lg} {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  &__menu {
    display: flex;
    margin-left: auto;
    margin-bottom: auto;
    background-color: $fiveColor;
    border: none;
    cursor: pointer;
    width: 62px;
    height: auto;
    text-align: center;
    padding: 20px;
    border-radius: 2px;
    &:hover {
      background-color: #1a0172;
    }
    @media #{$--lg} {
      display: none;
    }
  }
  & ul {
    margin: 0;
    display: none;
    text-decoration: none;
    width: 100%;
    list-style: none;
    justify-content: center;
    border-bottom: 1px solid white;
    flex-direction: column;
    padding: 0;
    z-index: 10;
    position: absolute;
    &.active {
      transition: 1s display;
      display: flex;
    }
    @media #{$--lg} {
      width: auto;
      display: flex;
      flex-direction: row;
      position: static;
    }
    & li {
      height: 60px;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 30px;
      cursor: pointer;
      background-color: #1a026890;
      border-bottom: 1px solid white;
      &:hover {
        transition: 0.4s background-color;
        background-color: #0a002b;
      }
      &.active {
        background-color: #0a002b;
      }
      & a {
        font-size: 14px;
        @media #{$--sm} {
          font-size: 16px;
        }
      }

      @media #{$--lg} {
        background-color: $fiveColor;
        border-bottom: none;
      }
    }
  }
}

.cover {
  display: flex;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  height: auto;
  @media #{$--md} {
    min-height: 80vh;
  }

  &--background-container {
    width: 100%;
    display: flex;
    z-index: 0;
    // filter: brightness(50%);
  }
  &--background-image {
    width: 100%;
    display: flex;
    height: auto;
    @media #{$--md} {
      min-height: 80vh;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    &--description {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      font-family: sans-serif;
      margin: auto;
      text-shadow: 0px 0px 10px rgb(71, 71, 71);
      @media #{$--sm} {
        font-size: 30px;
      }
      @media #{$--md} {
        max-width: 700px;
        font-size: 50px;
      }
    }
  }
}

.services {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: auto;
  min-height: 100vh;
  &-top {
    display: block;
    padding: 20px;
    margin-top: 60px;
  }
  &-title {
    text-align: center;
    font-size: 28px;
    font-family: sans-serif;
    letter-spacing: -0.5px;
    font-weight: 600;
    margin: 0 auto 5px;
    color: #2a166f;
    @media #{$--sm} {
      font-size: 40px;
    }
  }
  &-underline {
    width: 100%;
    margin: 10px auto;
    text-align: center;
    text-decoration: wavy;
    border: none;
    border-bottom: 1px dotted #2a166f;
    @media #{$--lg} {
      max-width: 80%;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto auto 60px;
    @media #{$--lg} {
      flex-direction: row;
      gap: 20px;
      max-width: 80%;
    }
    &--item {
      width: 90%;
      padding: 10px;
      margin: 20px auto;
      min-height: 300px;
      @media #{$--lg} {
        width: 100%;
        min-height: 400px;
        max-width: calc(100vw / 3);
      }
      &__image {
        display: block;
        border-radius: 10px;
        margin-top: 20px;
        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
        width: 50%;
        margin: auto;
        @media #{$--lg} {
          width: auto;
          max-height: 220px;
        }
      }
      &__title {
        color: $thirdColor;
        font-size: 24px;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        font-weight: 600;
        margin: 10px auto;
        text-align: center;
        @media #{$--sm} {
          font-size: 27px;
        }
        @media #{$--md} {
          font-size: 24px;
        }
      }
      &__description {
        text-align: center;
        font-size: 14px;
        margin: 10px auto;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        color: #868686;
        @media #{$--sm} {
          font-size: 16px;
        }
        @media #{$--md} {
          font-size: 14px;
        }
      }
    }
  }
}

.vision {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #427cac;
  // background: -moz-linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(123, 104, 209, 1) 10%,
  //   rgba(142, 124, 220, 1) 100%
  // );
  // background: -webkit-linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(123, 104, 209, 1) 10%,
  //   rgba(142, 124, 220, 1) 100%
  // );
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(123, 104, 209, 1) 10%,
  //   rgba(142, 124, 220, 1) 100%
  // );
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#8e7cdc",GradientType=1);
  &-container {
    width: 100%;
    padding: 40px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    &--item {
      margin: auto;
      min-height: 220px;
      font-family: sans-serif;
      text-align: center;
      width: 85%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        margin: 0 auto 5px;
        @media #{$--sm} {
          font-size: 40px;
        }
      }
      &__description {
        font-style: italic;
        color: #fff;
        font-size: 16px;
        margin: 5px auto 0;
        @media #{$--sm} {
          font-size: 20px;
        }
        @media #{$--md} {
          font-size: 22px;
        }
      }
    }
  }
}
.we {
  min-height: 100vh;
  height: auto;
  display: flex;
  background-color: #090027;
  text-align: center;
  color: #fff;
  z-index: 1;
  &-background {
    display: flex;
    width: 100%;
    height: auto;
    display: flex;
    margin: auto;
    z-index: 0;
  }

  &-container {
    margin: auto;
    display: flex;
    z-index: 1;
    flex-direction: column;
    padding: 0 20px;
    &--fi {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 30px auto;
      font-family: sans-serif;

      &__title {
        font-size: 28px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        margin: 0 auto 10px;
        @media #{$--sm} {
          font-size: 32px;
        }
        @media #{$--lg} {
          font-size: 40px;
        }
      }
      &__description {
        font-size: 16px;
        text-align: justify;
        margin: 10px auto;
        font-style: italic;
        @media #{$--xs} {
          font-size: 18px;
        }
        @media #{$--sm} {
          font-size: 22px;
        }
        @media #{$--lg} {
          font-size: 24px;
        }
      }
      @media #{$--lg} {
        width: 60%;
        margin: 20px auto;
      }
    }
  }
}

.footer {
  display: flex;
  background-color: #000;
  justify-content: space-between;
  color: #fff;
  min-height: 120px;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  @media #{$--md} {
    min-height: 20vh;
    flex-direction: row;
  }
  &-reserved {
    padding: 10px 20px;
    font-size: 15px;
    font-family: sans-serif;
    margin: 20px auto 10px;
    text-align: center;
    @media #{$--md} {
      margin: auto;
      text-align: left;
      padding: 0 20px;
      font-size: 15px;
      margin-left: 10%;
    }
  }
  &-social {
    padding: 10px 20px;
    display: flex;
    text-align: center;
    gap: 10px;
    color: #fff;
    font-family: sans-serif;
    margin: 0px auto 20px;
    @media #{$--md} {
      margin: auto;
      padding: 0 20px;
      font-size: 15px;
      margin-right: 10%;
    }
    & .fa-brands {
      cursor: pointer;
    }
  }
}
