html {
  background-color: white;

  font-style: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  display: block;
  font-style: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

*,
:after,
:before {
  box-sizing: border-box;
}

p {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
}
span {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

div {
  margin: 0;
}

.flex {
  display: flex !important;
}

.block {
  display: block !important;
}

.items-center {
  align-items: center;
}
.fs-1 {
  font-size: 10px;
}

.fs-2 {
  font-size: 16px;
}

.fs-3 {
  font-size: 20px;
}

.fs-4 {
  font-size: 25px;
}

.fs-5 {
  font-size: 30px;
}

.m-0 {
  margin: 0;
}
.mx-0 {
  margin: 0 auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}

.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-center {
  text-align: center;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.block {
  display: block;
}

.bg-primary {
  background-color: $primaryColor;
}

.bg-secondary {
  background-color: $secondaryColor;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}
.text-primary {
  color: $primaryColor;
}

.text-secondary {
  color: $secondaryColor;
}

.text-third {
  color: $thirdColor;
}

.fw-bold {
  font-weight: bold;
}

.fw-bolder {
  font-weight: bolder;
}

.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 100%;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  width: 100%;
  &-text {
    background-color: #d8dbe0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    white-space: nowrap;
    & svg {
      margin: auto;
    }
  }
  &-label {
    font-size: 1rem;
    margin: 0.2rem 0;
    font-family: sans-serif;
  }
}

.form-control {
  font-family: sans-serif;
  border: #a3a3a3 1px solid;
  font-weight: 400;
  line-height: 1.5px;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-left: -1px;
  padding: 0.375rem 0.75rem;
  flex: 1 1 auto;
  transition: outline 0.1s ease;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    transition: outline 0.15s ease;
    border: #bbd4ff 1px solid;
    outline: 3.5px solid rgba(141, 139, 255, 0.796);
    &.border-danger {
      border-color: #ff6565;
      outline: 3.5px solid rgba(255, 139, 139, 0.796);
    }
  }
  &::placeholder {
    color: #b4b4b4;
  }
}

.button-group {
  display: flex;
  width: auto;
}

.btn {
  padding: 0.375rem 0.75rem;
  text-align: center;
  border: 1px solid #000000;
  font-family: sans-serif;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled {
    opacity: 0.8;
  }
  &-success {
    border-color: $primaryColor;
    background-color: $primaryColor;
    color: #fff;
  }
  &-dark {
    border-color: $thirdColor;
    background-color: $thirdColor;
    color: #fff;
  }
  &-info {
    border-color: $fourColor;
    background-color: $fourColor;
    color: #fff;
  }
}

.text-danger {
  color: rgb(229, 83, 83);
}

.border-danger {
  border-color: rgb(229, 83, 83);
}

.alert {
  padding: 0.25rem;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 15px;
  border-radius: 0.25rem;
  &-danger {
    border: 0.5px solid #f7cbcb;
    background-color: #fadddd;
    color: #893232;
  }
}

.table {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  min-width: 450px;
  border-collapse: collapse;
  caption-side: bottom;
  border: 0 solid;
  border-color: inherit;

  & th {
    background-color: #2c2c2c;
    color: #fff;
    padding: 0.5rem;
  }
  & td {
    border: 0;
    border-style: double;
    border-color: #000000;
    border-bottom-width: 1px;
    padding: 10px 5px;
    background-color: white;
    color: rgba(44, 56, 74, 0.95);
    padding: 0.5rem;
  }
}
