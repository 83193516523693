.error-404 {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background-color: #f1f1f1;
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;

    &__title {
      width: 100%;
      font-size: 38px;
      font-weight: 600;
      color: $fiveColor;
      text-align: center;
      margin: 10px auto;
      font-family: sans-serif;
    }
    &__link {
      font-size: 18px;
      color: #4f4f4f;
      text-align: center;
      margin: 10px auto;
      font-family: sans-serif;
    }

    @media #{$--xs} {
      width: auto;
    }
    @media #{$--sm} {
      max-width: 600px;
    }
  }
}
