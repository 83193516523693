.exams {
  min-height: 100vh;
  background-color: #e9e9e9;
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;
  &__header {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #130027;
    padding: 20px;
    text-align: center;
    align-items: center;
    @media #{$--md} {
      flex-direction: row;
    }
    &__image {
      max-width: 170px;
      margin: 10px auto;
      width: 100%;
      height: auto;
      @media #{$--md} {
        margin: auto auto auto 0;
        max-width: 250px;
      }
    }
    &__text {
      font-size: 24px;
      color: #fff;
      margin: 10px auto;
      font-weight: 700;
      @media #{$--md} {
        margin: auto 0 auto 0;
        margin-right: auto;
      }
    }
    &__patient {
      font-size: 20px;
      color: #fff;
      text-transform: capitalize;
      margin: auto;
    }
    & .logoutButton {
      margin: 10px auto;
      width: 100%;
      max-width: 250px;
      @media #{$--md} {
        margin: auto 0 auto auto;
        margin-left: auto;
      }
    }
  }
  &-container {
    display: flex;
    width: 100%;
    min-height: 40vh;
    flex-direction: column;
    background-color: #e9e9e9;
    border-left: none;
    border-right: none;
    &__filter {
      display: flex;
      margin: 40px auto 15px;
      flex-direction: column;
      max-width: 700px;
      width: 100%;
      gap: 20px;
      padding: 0 20px;
      & input {
        border-radius: 0.25rem;
        width: 100%;
        flex: 1 1 auto;
      }
      @media #{$--md} {
        flex-direction: row;
      }
    }
    &__table {
      overflow: auto;
      min-height: 40vh;
      height: auto;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      width: 100%;
      @media #{$--lg} {
        width: 80%;
      }
    }
    &__paginate {
      padding: 20px;
      margin: auto auto 0;
      display: flex;
    }
  }

  & .footer {
    margin-top: auto;
  }
}
